import React from "react";
import Loading from "../../assets/img/bookingfinishloader.gif";
import * as S from "./styles";

// import { Container } from './styles';

function Loader({
	openLoader,
	isBooking = true,
	isRegister = false,
	isLogin = false,
	isSaving = false,
	isGeneral = false,
}) {
	if (isSaving) {
		isBooking = false;
		isRegister = false;
		isLogin = false;
		isGeneral = false;
	}
	return (
		<S.MuiDialog open={openLoader}>
			<img src={Loading} alt='Carregando' />

			{isBooking && <span>Aguarde, enquanto concluímos sua reserva.</span>}
			{isRegister && <span>Aguarde, estamos criando a sua conta.</span>}
			{isLogin && <span>Aguarde, enquanto estamos te autenticando.</span>}
			{isSaving && <span>Aguarde, enquanto salvamos as informações.</span>}
			{isGeneral && <span>Aguarde...</span>}
		</S.MuiDialog>
	);
}

export default Loader;
