import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
    font-family: 'Poppins';
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: auto;
    padding-top: 20px;
  `}
`;

export const BlankCard = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    padding: 12px;
    padding-top: 9px;
    padding-bottom: 27px;
    margin-bottom: 40px;

    .title {
      padding-bottom: 8px;
      padding-left: 5px;
      border-bottom: 1px solid ${rgba('#707070', 0.21)};
      margin-bottom: 20px;
    }

    .title2 {
      margin-bottom: 10px;
    }
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal bold 14px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  `}
`;

export const SideHeader = styled.div`
  ${({ theme }) => css`
    background-color: #e0e0e0;
    height: 35px;
    font-family: 'Poppins';
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px;

    &.first {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .airplane-badge {
      width: 17px;
      height: 17px;
      margin-left: 5px;
      padding-top: 7px;
    }

    .date-header {
      text-transform: capitalize;
    }
  `}
`;

export const BlankCardOnly = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
  `}
`;

export const PagamentoLabel = styled.span`
  ${({ theme }) => css`
    display: flex;

    text-align: left;
    font: normal normal bold 14px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding: 15px 15px;
  `}
`;

export const Connection = styled.div`
  ${({ theme }) => css`
    padding: 15px 5px;
    display: flex;
    width: 100%;
    font-size: 12px;
    justify-content: space-between;

    .company {
      display: flex;

      img {
        background-color: #ededed;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        object-fit: contain;
        padding: 3px;
      }
      div {
        padding: 0px 5px;

        display: flex;
        flex-wrap: wrap;
        span {
          width: 100%;
        }

        .connection {
          text-align: left;
          font: normal normal 200 10px 'Poppins';
          letter-spacing: 0px;
        }

        .name {
          font-size: 12px;
        }
      }
    }
    .airport {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
    }

    .plus-day {
      color: red;
    }
  `}
`;

export const Total = styled.div`
	${({ theme }) => css`
		display: flex;
		justify-content: space-between;
		padding: 15px 15px;
		font-size: 11px;

		span {
			font-weight: 200;
		}
		.semibold {
			font-weight: 600;
		}
		.bold {
			font-weight: Bold;
		}

		&.wrap {
			flex-wrap: wrap;
			padding: 15px 15px;

			div {
				width: 100%;
				display: flex;
				justify-content: space-between;
				padding-top: 5px;
				padding-bottom: 5px;

				&.total {
					font-size: 13px;
					padding-top: 0px;
					padding-bottom: 0px;
				}
			}
		}
	`}
`;

export const Spacer = styled.div`
  height: 70px;
`;

export const Subtext = styled.div`
  font-weight: 200;
  font-size: 11px;
  padding: 5px;
`;

export const BlockBuyText = styled.div`
  font-weight: 600;
  font-size: 13px;
  padding: 5px;
  color: red;
`;

export const FlightSummaryCardCategory = styled.div`
  display: flex;
  padding-bottom: 8px;
  padding-left: 5px;
  border-bottom: 1px solid ${rgba('#707070', 0.21)};
  margin-bottom: 20px;

  img {
    width: 23px;
    margin-right: 15px;
  }

  .category {
    width: 100%;
    font-size: 11px;
  }

  .category-details {
    color: #2271cf;
    display: flex;
    justify-content: left;

    span {
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
`;

export const AlertStyle = styled.div`
	${({ theme }) => css`
		.text {
			color: rgba(255, 247, 226, 1) !important;
		}

		.value {
			font-weight: 700;
			background-color: rgba(255, 247, 226, 1) !important;
			color: rgba(255, 152, 0, 1) !important;
			padding: 0px 5px;
	`}
`;