import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";
import { useEffect, useState } from "react";

import * as S from "./styles";

import Separator from "../../Separator";
import { useGlobal } from "../../../contexts/globalContext";
import Alert from "@material-ui/lab/Alert";
import BusTraveling from "./../../../assets/icons/BusTraveling.svg";
import BusTravelingBack from "./../../../assets/icons/BusTravelingBack.svg";

const BusSideSummary = ({
	userPoints,
	setCashPrice,
	setPointsPrice,
	minimumPointsToBuy,
	searchState,
	goingRoute,
	returnRoute,
	selectedGoingSeats,
	selectedReturnSeats,
	paymentInPoints,
	paymentInMoney,
}) => {
	const { signed, user } = useGlobal();
	const [coinAlias, setCoinAlias] = useState("Pontos");
	const [shortenCoinAlias, setShortenCoinAlias] = useState("Pts");
	const [points, setPoints] = useState(0);
	const [cash, setCash] = useState(0);

	useEffect(() => {
		if (goingRoute && returnRoute) {
			let pointsDepart = goingRoute.pointsValue || 0;
			let pointsReturn = returnRoute.pointsValue || 0;
			setPoints(pointsDepart + pointsReturn);

			let cashDepart = goingRoute.cashValue || 0;
			let cashReturn = returnRoute.cashValue || 0;
			setCash(cashDepart + cashReturn);
		} else if (goingRoute) {
			let pointsDepart = goingRoute.pointsValue || 0;
			setPoints(pointsDepart);

			let cashDepart = goingRoute.cashValue || 0;
			setCash(cashDepart);
		}
		// eslint-disable-next-line
	}, [goingRoute, returnRoute, searchState]);

	let singlePoints = points / searchState.passageiros;
	let singleCash = cash / searchState.passageiros;

	if (searchState?.idavolta) {
		setCashPrice(cash);
		setPointsPrice(points);
	} else {
		setCashPrice(singleCash);
		setPointsPrice(singlePoints);
	}

	useEffect(() => {
		setCoinAlias(user?.campaign?.campaignConfig?.coinAlias || "Pontos");
		setShortenCoinAlias(user?.campaign?.campaignConfig?.shortenCoinAlias || "Pts");
	}, [user?.campaign?.campaignConfig?.coinAlias, user?.campaign?.campaignConfig?.shortenCoinAlias]);

	const Header = ({ type, date, ...props }) => {
		return (
			<S.SideHeader {...props}>
				<div>
					<span>{type}</span>
					<img className="bus-badge" src={type === "IDA" ? BusTraveling : BusTravelingBack} alt="icon" />{" "}
				</div>
				<span className="date-header">
					{date !== undefined &&
						date !== "Invalid Date" &&
						format(parseISO(date), "EEEE, dd MMM yyyy", {
							locale: pt,
						}).replace("-feira", "")}
				</span>
			</S.SideHeader>
		);
	};

	const Route = ({ img, company, routeCode, departureHour, departureStation, arrivalHour, arrivalStation, selectedSeats, ...props }) => {
		return (
			<S.Route>
				<div className="route">
					<div className="company">
						{img && <img src={img} alt="company" />}
						<div>
							{img && <span className="name">{company}</span>}
							<span className="connection truncate" title={departureStation}>{`${!img ? "Conexão " : ""}${departureStation}`}</span>
						</div>
					</div>
					<div className="time-station">
						<div className="departure">
							<span className="truncate" title={departureHour}>
								{departureHour !== undefined && departureHour !== "Invalid Date" && format(parseISO(departureHour), "HH:mm")}
							</span>
							<span className="truncate" title={departureStation}>
								{departureStation}
							</span>
						</div>
						<div className="separator">
							<span>{">"}</span>
						</div>
						<div className="arrival">
							<span className="truncate" title={arrivalHour}>
								{arrivalHour !== undefined && arrivalHour !== "Invalid Date" && format(parseISO(arrivalHour), "HH:mm")}
							</span>
							<span className="truncate" title={arrivalStation}>
								{arrivalStation}
							</span>
						</div>
					</div>
				</div>
				<div className="seats">
					<span>Assentos selecionados: </span>
					<span className="numbers">{selectedSeats.join(", ")}</span>
				</div>
			</S.Route>
		);
	};

	return (
		<div>
			<Header className="first" type="IDA" date={searchState?.datas.startDate} />
			<S.BlankCardOnly>
				{goingRoute ? (
					<>
						<Route
							routeCode={goingRoute.key}
							img={goingRoute.company.logo}
							company={goingRoute.company.name}
							departureHour={goingRoute.departure}
							arrivalHour={goingRoute.arrival}
							departureStation={goingRoute.origin}
							arrivalStation={goingRoute.destination}
							selectedSeats={selectedGoingSeats}
						/>
					</>
				) : (
					""
				)}
			</S.BlankCardOnly>
			{searchState?.idavolta && (
				<>
					<Header type="VOLTA" date={searchState?.datas.endDate} />
					<S.BlankCardOnly>
						{returnRoute ? (
							<>
								<Route
									routeCode={returnRoute.key}
									img={returnRoute.company.logo}
									company={returnRoute.company.name}
									departureHour={returnRoute.departure}
									arrivalHour={returnRoute.arrival}
									departureStation={returnRoute.origin}
									arrivalStation={returnRoute.destination}
									selectedSeats={selectedReturnSeats}
								/>
							</>
						) : (
							""
						)}
					</S.BlankCardOnly>
				</>
			)}
			<Separator className="solid" />
			<S.BlankCardOnly>
				<S.PagamentoLabel>Pagamento</S.PagamentoLabel>
				<S.Total style={{ marginTop: "-15px" }}>
					<span className="bold">Valor</span>
				</S.Total>
				<S.Total style={{ marginTop: "-25px" }}>
					<span className="total">{coinAlias}</span>
					<span className="bold">
						{signed && !user?.guest && !user?.campaign?.disablePointActivity ? shortenCoinAlias : "R$"}{" "}
						{new Intl.NumberFormat("pt-BR", {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(points)}
					</span>
				</S.Total>

				{user?.campaign?.acceptCashPayment && (
					<S.Total style={{ marginTop: "-25px" }}>
						<span className="total">Dinheiro</span>
						<span className="bold">
							{"R$ "}
							{new Intl.NumberFormat("pt-BR", {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).format(cash)}
						</span>
					</S.Total>
				)}
				<Separator className="solid thin" margin={15} />
				<S.BlankCardOnly>
					<S.Total className="total-pontos">
						<span className="bold total">Valor do Saldo</span>
						<span className="bold">{paymentInPoints}</span>
					</S.Total>
					{user?.campaign?.acceptCashPayment && (
						<S.Total className="total-reais">
							<span className="bold total">Total em R$</span>
							<span className="bold total">
								{new Intl.NumberFormat("pt-BR", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}).format(paymentInMoney)}
							</span>
						</S.Total>
					)}
				</S.BlankCardOnly>
				{signed && userPoints < minimumPointsToBuy && (
					<S.AlertStyle>
						<Alert variant="filled" severity="warning">
							<span className="text">
								Você deve possuir ao menos <br />
								<span className="value">
									{minimumPointsToBuy} {coinAlias}
								</span>{" "}
								para realizar esta compra!
							</span>
						</Alert>
					</S.AlertStyle>
				)}
			</S.BlankCardOnly>
		</div>
	);
};

export default BusSideSummary;
