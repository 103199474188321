import React from "react";
import * as S from "./styles";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import { parseISO, format, differenceInCalendarDays } from "date-fns";
import BusStep from "../BusStep/";
import { pt } from "date-fns/locale";

const BusSegmentOption = ({ bus, company, classType }) => {
	const theme = useTheme();
	const downOfXs = useMediaQuery(theme.breakpoints.down("xs"));

	const diffDays = differenceInCalendarDays(new Date(bus?.departArrivalDate), new Date(bus?.departDate));

	const diffDaysReturn = differenceInCalendarDays(new Date(bus?.returnArrivalDate), new Date(bus?.returnDate));

	let busData = bus.bus ? bus.bus : bus;
	console.log("bus", busData);

	return (
		<S.BusSegmentOption className={`${bus ? "not-unique" : ""}`}>
			{downOfXs ? (
				<Grid container>
					<S.GridItemFlex item xs={12}>
						{busData.departDate !== undefined &&
							busData.departDate !== "Invalid Date" &&
							format(parseISO(busData.departDate), "dd MMMM", {
								locale: pt,
							})}
					</S.GridItemFlex>
					<S.GridItemFlex item xs={2}>
						<span className="company-name">{company}</span>
					</S.GridItemFlex>
					<S.GridItemFlex item xs={2}>
						<span className="departure-date">{format(parseISO(busData.departDate), "HH:mm")}</span>
					</S.GridItemFlex>
					<S.GridItemFlex className="select-option" item xs={2}>
						<span className="arrival-date">
							{format(parseISO(busData.departArrivalDate), "HH:mm")}
							<span className="plus-day">{diffDays > 0 ? `+${diffDays}` : ""}</span>
						</span>
					</S.GridItemFlex>
					<BusStep steps={busData} departDate={busData.departDate} departArrivalDate={busData.departArrivalDate} />
					{busData.returnDate && (
						<>
							<S.GridItemFlex item xs={12}>
								{format(parseISO(busData.returnDate), "dd MMMM", {
									locale: pt,
								})}
							</S.GridItemFlex>
							<S.GridItemFlex item xs={2}>
								<span className="company-name">{company}</span>
							</S.GridItemFlex>
							<S.GridItemFlex item xs={2}>
								<span className="departure-date">{format(parseISO(busData.returnDate), "HH:mm")}</span>
							</S.GridItemFlex>
							<S.GridItemFlex className="select-option" item xs={2}>
								<span className="arrival-date">
									{format(parseISO(busData.returnArrivalDate), "HH:mm")}
									<span className="plus-day">{diffDaysReturn > 0 ? `+${diffDaysReturn}` : ""}</span>
								</span>
							</S.GridItemFlex>
							<BusStep steps={busData} departDate={busData.returnDate} departArrivalDate={busData.returnArrivalDate} />
						</>
					)}
				</Grid>
			) : (
				<Grid container>
					<S.GridItemFlex item xs={12}>
						{busData.departDate !== undefined &&
							busData.departDate !== "Invalid Date" &&
							format(parseISO(busData.departDate), "dd MMMM", {
								locale: pt,
							})}
					</S.GridItemFlex>
					<S.GridItemFlex item lg={1} md={1} sm={1} xs={1}>
						<span className="company-name"> {company}</span>
					</S.GridItemFlex>
					<S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
						<span className="departure-date">{format(parseISO(busData.departDate), "HH:mm")}</span>
					</S.GridItemFlex>
					<S.GridItemFlex item lg={4} md={4} sm={4} xs={4}>
						<BusStep steps={busData} departDate={busData.departDate} departArrivalDate={busData.departArrivalDate} />
					</S.GridItemFlex>
					<S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
						<span className="arrival-date">{format(parseISO(busData.departArrivalDate), "HH:mm")}</span>
					</S.GridItemFlex>

					{busData.returnDate && (
						<>
							<S.GridItemFlex item xs={12}>
								{format(parseISO(busData.returnDate), "dd MMMM", {
									locale: pt,
								})}
							</S.GridItemFlex>
							<S.GridItemFlex item lg={1} md={1} sm={1} xs={1}>
								<span className="company-name"> {company}</span>
							</S.GridItemFlex>
							<S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
								<span className="departure-date">{format(parseISO(busData.returnDate), "HH:mm")}</span>
							</S.GridItemFlex>
							<S.GridItemFlex item lg={4} md={4} sm={4} xs={4}>
								<BusStep steps={busData} departDate={busData.returnDate} departArrivalDate={busData.returnArrivalDate} />
							</S.GridItemFlex>
							<S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
								<span className="arrival-date">{format(parseISO(busData.returnArrivalDate), "HH:mm")}</span>
							</S.GridItemFlex>
						</>
					)}
				</Grid>
			)}
		</S.BusSegmentOption>
	);
};

export default BusSegmentOption;
