import api from "../services/api";
import getHeaders from "../utils/returnHeaders";

const getHotels = async (data) => {
	const header = getHeaders();

	return api.post(`/hotel/available`, JSON.stringify(data), header);
};

const getHotelDetails = async (hotelCode) => {
	const header = getHeaders();

	return api.get(`/hotel/details/${hotelCode}`, header);
};

export { getHotels, getHotelDetails };
