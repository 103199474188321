import api from "../services/apiHub";
import getHeaders from "../utils/returnHeadersHub";
import deburr from "lodash/deburr";

export default class EntertainmentRepository {
	searchAutoComplete = async (term) => {
		const headers = getHeaders();
		const normalizedTerm = deburr(term.toLowerCase());

		try {
			const body = {
				filter: {
					name: normalizedTerm,
				},
			};

			const response = await api.post("/api/services/entertainment/auto-complete", body, headers);
			const { activities, locations } = response.data;
			return {
				data: {
					activities,
					locations,
				},
				headers,
			};
		} catch (error) {
			console.error("Error fetching search results:", error);
			return {
				data: {
					activities: [],
					locations: [],
				},
				headers,
			};
		}
	};

	checkLocationAvailability = async (selectedRoute, type) => {
		const headers = getHeaders();

		if (!selectedRoute || !type) {
			console.error("Rota ou tipo inválidos fornecidos.");
			return null;
		}

		const body = {
			filter: {
				route: selectedRoute,
				type: type,
			},
		};

		try {
			const response = await api.post("/api/services/entertainment/search", body, headers);
			return response.data;
		} catch (error) {
			console.error("Erro ao verificar a disponibilidade da localização:", error);
			return null;
		}
	};

	getKey = async (key) => {
		const headers = getHeaders();
		const body = {
			filter: {
				key: key,
			},
		};

		try {
			const response = await api.post("/api/services/entertainment/get", body, headers);
			return response.data;
		} catch (error) {
			console.error("Error while fetching key:", error);
			return null;
		}
	};

	checkAvailability = async (activityId, refToken, passengerRate, startDate, endDate = "") => {
		const headers = getHeaders();

		let activityCategory = "";
		if (activityId.startsWith("4")) {
			activityCategory = "transfer";
		} else if (activityId.startsWith("5")) {
			activityCategory = "ticket";
		} else if (activityId.startsWith("6")) {
			activityCategory = "tour";
		}

		const adjustedEndDate = activityCategory === "transfer" ? endDate : "";

		const body = {
			filter: {
				activityId: activityId,
				refToken: refToken,
				passengerRate: passengerRate,
				startDate: startDate,
				endDate: adjustedEndDate,
			},
		};

		try {
			const response = await api.post("/api/services/entertainment/get", body, headers);
			return response.data;
		} catch (error) {
			console.error("Error fetching availability:", error);
			return null;
		}
	};

	createBooking = async (bookingData) => {
		const headers = getHeaders();

		try {
			const response = await api.post("/api/services/entertainment/booking", bookingData, headers);
			console.info("Booking criado com sucesso", response);
			return response;
		} catch (error) {
			console.error("Erro ao criar o booking", error);
			throw error;
		}
	};
}
